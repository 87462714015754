<template>
  <div class="aiz-user-panel">
    <div class="card">
      <div class="card-header">
          <h5 class="mb-0 h6">{{ $t('shen-qing-tui-kuan-qing-qiu') }}</h5>
      </div>
      <div class="card-body">
        <table class="table mb-0  " :class="dataList.length == 0 ? ' footable aiz-table' : ''">
          <thead>
              <tr>
                <th>#</th>
                <th data-breakpoints="lg">{{ $t('ri-qi') }}</th>
                <th>{{ $t('ding-dan-bian-hao') }}</th>
                <th data-breakpoints="lg">{{ $t('chan-pin') }}</th>
                <th data-breakpoints="lg">{{ $t('jin-e') }}</th>
                <th>{{ $t('zhuang-tai') }}</th>
              </tr>
          </thead>
            <tbody>
              <tr v-for="(item, i) in dataList" :key="i">
                  <td>{{i + 1}}</td>
                  <td>11</td>
                  <td>11</td>
                  <td>11</td>
                  <td>11</td>
                  <td>
                    <span class="badge badge-inline badge-danger">{{ $t('yi-ju-jue-0') }}</span>
                    <a href="javascript:void(0);" @click="showReason" class="btn btn-soft-primary btn-icon btn-circle btn-sm" :title="$t('ju-jue-yuan-yin-2')">
                        <i class="las la-eye"></i>
                    </a>
                  </td>
              </tr>
              <tr class="footable-empty" v-if="dataList.length == 0"><td colspan="6">{{ $t('shi-mo-du-mei-zhao-dao') }}</td></tr>
            </tbody>
        </table>
        <div class="aiz-pagination" v-show="page.total > 1">
          <pageBar :totalPage="page.total" :page="page.page"></pageBar>
        </div>
      </div>
    </div>
    <Popup v-model="showPopup" position="center">
      <div class="popup-box  reject_reason_show_modal" id="modal-basic">
        <div class="modal-dialog">
          <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title h6">{{ $t('shen-qing-tui-kuan-yuan-yin') }}</h5>
                <button type="button" class="close" @click="cancel"></button>
              </div>
              <div class="modal-body reject_reason_show">
                <div class="card-body">
                  <div class="form-group row">
                    <label class="col-lg-2 col-from-label"><b>{{ $t('yuan-yin-1') }}</b></label>
                    <div class="col-lg-8">
                      <p class="bord-all pad-all">{{refundData.reason}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-light" @click="cancel">{{ $t('guan-bi') }}</button>
              </div>
          </div>
        </div>
      </div>
    </Popup>
  </div>
</template>
<script>
import pageBar from '@/components/page'
import {Popup} from 'vant'
export default {
  components: {
    pageBar,
    Popup
  },
  data() {
    return {
      showPopup: false,
      refundData: {},
      dataList: [],
      page: {
        page: 1,
        limit: 10,
        total: 1
      }
    }
  },
  methods: {
    showReason() {
      this.showPopup = true
    },
    cancel() {
      this.showPopup = false
    }
  }
}
</script>